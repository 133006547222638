import {
  mapDirectoriesList,
  mapQueryCustomReportsList,
  mapQueryDocAnalyticsFilesCount,
  mapQueryDocAnalyticsFilesList,
  mapQueryDocAnalyticsSitesFilter,
  mapQueryDrivesDirectoryDetailsCount,
  mapQueryDrivesDirectoryDetailsList,
  mapQuerySharesDirectoryDetailsCount,
  mapQuerySharesDirectoryDetailsList,
  mutationCreateCustomAnalysis,
  queryCustomReportsList,
  queryDirectoryList,
  queryDocAnalyticsFilesCount,
  queryDocAnalyticsFilesList,
  queryDocAnalyticsSitesFilter,
  queryDrivesDirectoryDetailsCount,
  queryDrivesDirectoryDetailsList,
  querySharesDirectoryDetailsCount,
  querySharesDirectoryDetailsList
} from './queries'
import { DATA_SOURCE_ID, PAGE, SEARCH_QUERY } from '../../constants'
import { FilterParams } from '../../interfaces'
import apiService from '../../services/api/apiService'
import graphqlService from '../../services/graphqlService'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

type DocAnalyticsState = {
  docAnalyticsStatus: DocAnalyticsStatus
  filesList: DocAnalyticsFilesObject[]
  filesCount: number
  sitesFilterList: SitesFilterObj[]
  fileTypeFilterList: FileTypeFilterObj[]
  fileTypeSkippedFilterList: FileTypeFilterObj[]
  fileSizeWidget: WidgetFileSizeObj[]
  lastUpdatedWidget: LastUpdatedWidgetObj[]
  creationTimeWidget: CreationTimeWidgetObj[]
  skipReasonWidget: SkipReasonWidgetObj[]
  customReports: {
    list?: CustomReportsListObj[]
  }
  directoryDetails: {
    shares?: SharesDirectoryDetailsListObj[]
    drives?: DrivesDirectoryDetailsListObj[]
    sharesCount?: number
    drivesCount?: number
  }
  directories: {
    [path: string]: DirectoryDetailsList
  }
}

export enum STATUS {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED',
  AVAILABLE = 'AVAILABLE'
}

export type ExtendedStatus = STATUS | '' | '-'
export interface DocAnalyticsStatus {
  lastUpdatedOn: number
  status: ExtendedStatus
}
export interface FetchDocAnalyticsStatusParams {
  [DATA_SOURCE_ID]?: string
}

export interface TriggerDocAnalyticsParams {
  [DATA_SOURCE_ID]?: string
}

//Files
export interface FetchDocAnalyticsFilesListParams {
  [DATA_SOURCE_ID]?: string
  filters?: FilterParams
  [PAGE]: number
}

export type DocAnalyticsFilesObject = {
  id: string
  name: string
  extension: string
  size: number
  skipped: boolean
  skipReason?: string
  checksum?: string
  lastModifiedTimestamp: string
  createdTimestamp?: string
  objectLink: string
}

export type DocAnalyticsFilesResponseType = {
  list: DocAnalyticsFilesObject[]
}

export type DocAnalyticsFilesCountResponseType = {
  total: number
}

//Sites Filter
export interface SitesFilterParams {
  [DATA_SOURCE_ID]: string
  [SEARCH_QUERY]?: string
}

export type SitesFilterObj = {
  siteId: string
  name: string
}

export type SitesFilterResponseType = {
  list: SitesFilterObj[]
}

//File Type filter
export interface FileTypeFilterParams {
  [DATA_SOURCE_ID]: string
  skipped?: boolean
  folderPath?: string
}

export type DirectoryDetailsCountResponseType = {
  total: number
}

export interface DocAnalyticsWidgetParams {
  [DATA_SOURCE_ID]: string
  skipped?: boolean
  folderPath?: string
}

export type FileTypeFilterObj = {
  extension: string
  count: number
  totalDataScannedBytes?: number
  skippedDataScannedBytes?: number
  highSensitiveCount?: number
  skippedCount?: number
  totalSensitiveCount?: number
}

export interface ISizeRange {
  lt: number
  gte: number
}

export type WidgetFileSizeObj = {
  sizeRange: ISizeRange
  count: number
}

export type LastUpdatedWidgetObj = {
  lastModifiedDateCategory: string
  count: number
}

export type CreationTimeWidgetObj = {
  createdDateCategory: string
  count: number
}

export type SkipReasonWidgetObj = {
  skipReason: string
  count: number
}

//Custom Reports List
export interface CustomReportsListParams {
  [PAGE]: number
  filters?: FilterParams
}

export interface ICustomReportsAttachment {
  id: string
  name: string
  url: string
}

export type CustomReportsListObj = {
  id: string
  title: string
  name?: string
  path?: string
  extension: string
  updateTimestamp: string
  icon: string
  attachments: ICustomReportsAttachment
  status?: STATUS
  isPending?: boolean
}

export type CustomReportsListResponseType = {
  list: CustomReportsListObj[]
}

export interface DirectoryDetailsListParams {
  [PAGE]: number
  dataSourceId: string
  path?: string
}
//For SMB
export type SharesDirectoryDetailsListObj = {
  name?: string
  shareId?: number
  path?: string
  size?: number
  attributeInstancesCount?: number
  piiDataDocumentsCount?: number
  skippedDocumentsCount?: number
  processedDocumentsCount?: number
  documentsCount?: number
}

export type SharesDirectoryDetailsResponseType = {
  list: SharesDirectoryDetailsListObj[]
}

export type DirectoryDetailsList = {
  list?: { name: string; path: string }[]
  count?: number
  error: string
}

//For OneDrive and Sharepoint
export type DrivesDirectoryDetailsListObj = {
  driveId?: string
  driveName?: string
  size?: number
  attributeInstancesCount?: number
  piiDataDocumentsCount?: number
  skippedDocumentsCount?: number
  processedDocumentsCount?: number
  documentsCount?: number
}

export type DrivesDirectoryDetailsResponseType = {
  list: DrivesDirectoryDetailsListObj[]
}

export type WidgetKeys =
  | 'fileTypeFilterList'
  | 'fileTypeSkippedFilterList'
  | 'fileSizeWidget'
  | 'creationTimeWidget'
  | 'skipReasonWidget'
  | 'lastUpdatedWidget'
  | 'fileTypeSkippedFilterList'

//Initial Reducer State
const initialState: DocAnalyticsState = {
  docAnalyticsStatus: {
    lastUpdatedOn: -1,
    status: '-'
  },
  filesList: [],
  filesCount: 0,
  sitesFilterList: [],
  fileTypeFilterList: [],
  fileTypeSkippedFilterList: [],
  fileSizeWidget: [],
  lastUpdatedWidget: [],
  creationTimeWidget: [],
  skipReasonWidget: [],
  customReports: {},
  directoryDetails: {},
  directories: {
    '': {
      error: ''
    }
  }
}

export const ACTION_FETCH_DOC_ANALYTICS_STATUS = 'docAnalytics/status'
export const fetchDocAnalyticsStatus = createAsyncThunk(
  ACTION_FETCH_DOC_ANALYTICS_STATUS,
  async (params: FetchDocAnalyticsStatusParams) => {
    const result = await apiService.getDocAnalyticsStatus(params)
    return result
  }
)

export const ACTION_RUN_DOC_ANALYTICS = 'docAnalytics/trigger'
export const triggerDocAnalytics = createAsyncThunk(
  ACTION_RUN_DOC_ANALYTICS,
  async (params: TriggerDocAnalyticsParams) => {
    const result = await apiService.runDocAnalytics(params)
    return result
  }
)

export const ACTION_FETCH_DOC_ANALYTICS_FILES_LIST = 'docAnalytics/fetchFilesList'
export const fetchDocAnalyticsFilesList = createAsyncThunk(
  ACTION_FETCH_DOC_ANALYTICS_FILES_LIST,
  async (params: FetchDocAnalyticsFilesListParams) => {
    const resultRaw = await graphqlService.execute(queryDocAnalyticsFilesList(params))
    return mapQueryDocAnalyticsFilesList(resultRaw)
  }
)

export const ACTION_FETCH_DOC_ANALYTICS_FILES_COUNT = 'docAnalytics/fetchFilesCount'
export const fetchDocAnalyticsFilesCount = createAsyncThunk(
  ACTION_FETCH_DOC_ANALYTICS_FILES_COUNT,
  async (params: FetchDocAnalyticsFilesListParams) => {
    const resultRaw = await graphqlService.execute(queryDocAnalyticsFilesCount(params))
    return mapQueryDocAnalyticsFilesCount(resultRaw)
  }
)

export const ACTION_FETCH_SITES_FILTER = 'docAnalytics/filter/sites'
export const fetchSitesFilter = createAsyncThunk(
  ACTION_FETCH_SITES_FILTER,
  async (params: SitesFilterParams) => {
    const resultRaw = await graphqlService.execute(queryDocAnalyticsSitesFilter(params))
    return mapQueryDocAnalyticsSitesFilter(resultRaw)
  }
)

export const ACTION_FETCH_FILE_TYPE_FILTER = 'docAnalytics/filter/fileType'
export const fetchFileTypeFilter = createAsyncThunk(
  ACTION_FETCH_FILE_TYPE_FILTER,
  async (params: FileTypeFilterParams) => {
    const result = await apiService.getDocAnalyticsFileTypeFilterList(params)
    return result
  }
)

export const ACTION_FETCH_SKIPPED_FILE_TYPE_FILTER = 'docAnalytics/filter/skippedFileType'
export const fetchSkippedFileTypeFilter = createAsyncThunk(
  ACTION_FETCH_SKIPPED_FILE_TYPE_FILTER,
  async (params: FileTypeFilterParams) => {
    const result = await apiService.getDocAnalyticsFileTypeFilterList(params)
    return result
  }
)

export const ACTION_FETCH_WIDGET_FILE_SIZE = 'docAnalytics/widget/fileSize'
export const fetchWidgetFileSize = createAsyncThunk(
  ACTION_FETCH_WIDGET_FILE_SIZE,
  async (params: DocAnalyticsWidgetParams) => {
    const result = await apiService.getDocAnalyticsFileSizeList(params)
    return result
  }
)

export const ACTION_FETCH_WIDGET_LAST_UPDATED = 'docAnalytics/fetch/lastUpdated'
export const fetchWidgetLastUpdated = createAsyncThunk(
  ACTION_FETCH_WIDGET_LAST_UPDATED,
  async (params: DocAnalyticsWidgetParams) => {
    const result = await apiService.getDocAnalyticsLastUpdatedList(params)
    return result
  }
)

export const ACTION_FETCH_WIDGET_CREATION_TIME = 'docAnalytics/fetch/creationTime'
export const fetchWidgetCreationTime = createAsyncThunk(
  ACTION_FETCH_WIDGET_CREATION_TIME,
  async (params: DocAnalyticsWidgetParams) => {
    const result = await apiService.getDocAnalyticsCreationTime(params)
    return result
  }
)

export const ACTION_FETCH_WIDGET_SKIP_REASON = 'docAnalytics/fetch/skipReason'
export const fetchWidgetSkipReason = createAsyncThunk(
  ACTION_FETCH_WIDGET_SKIP_REASON,
  async (params: DocAnalyticsWidgetParams) => {
    const result = await apiService.getDocAnalyticsSkipReason(params)
    return result
  }
)

export const ACTION_FETCH_CUSTOM_REPORTS_LIST = 'docAnalytics/fetch/customReportsList'
export const fetchCustomReportsList = createAsyncThunk(
  ACTION_FETCH_CUSTOM_REPORTS_LIST,
  async (params: CustomReportsListParams) => {
    const resultRaw = await graphqlService.execute(queryCustomReportsList(params))
    return mapQueryCustomReportsList(resultRaw)
  }
)

//Directory Details List for SMB
export const ACTION_FETCH_SHARES_DIRECTORY_DETAILS_LIST =
  'docAnalytics/fetch/sharesDirectoryDetailsList'
export const fetchSharesDirectoryDetailsList = createAsyncThunk(
  ACTION_FETCH_SHARES_DIRECTORY_DETAILS_LIST,
  async (params: DirectoryDetailsListParams) => {
    const resultRaw = await graphqlService.execute(querySharesDirectoryDetailsList(params))
    return mapQuerySharesDirectoryDetailsList(resultRaw)
  }
)
export const ACTION_FETCH_SHARES_DIRECTORY_DETAILS_COUNT =
  'docAnalytics/fetch/sharesDirectoryDetailsCount'
export const fetchSharesDirectoryDetailsCount = createAsyncThunk(
  ACTION_FETCH_SHARES_DIRECTORY_DETAILS_COUNT,
  async (params: DirectoryDetailsListParams) => {
    const resultRaw = await graphqlService.execute(querySharesDirectoryDetailsCount(params))
    return mapQuerySharesDirectoryDetailsCount(resultRaw)
  }
)

//Directory Details List for OneDrive & Sharepoint
export const ACTION_FETCH_DRIVES_DIRECTORY_DETAILS_LIST =
  'docAnalytics/fetch/drivesDirectoryDetailsList'
export const fetchDrivesDirectoryDetailsList = createAsyncThunk(
  ACTION_FETCH_DRIVES_DIRECTORY_DETAILS_LIST,
  async (params: DirectoryDetailsListParams) => {
    const resultRaw = await graphqlService.execute(queryDrivesDirectoryDetailsList(params))
    return mapQueryDrivesDirectoryDetailsList(resultRaw)
  }
)
export const ACTION_FETCH_DRIVES_DIRECTORY_DETAILS_COUNT =
  'docAnalytics/fetch/drivesDirectoryDetailsCount'
export const fetchDrivesDirectoryDetailsCount = createAsyncThunk(
  ACTION_FETCH_DRIVES_DIRECTORY_DETAILS_COUNT,
  async (params: DirectoryDetailsListParams) => {
    const resultRaw = await graphqlService.execute(queryDrivesDirectoryDetailsCount(params))
    return mapQueryDrivesDirectoryDetailsCount(resultRaw)
  }
)

export type CreateCustomAnalysisParams = {
  path: string
  name: string
  datasourceId: string
}
export const ACTION_CREATE_CUSTOM_ANALYSIS = 'docAnalytics/create/customAnalysis'
export const createCustomAnalysis = createAsyncThunk(
  ACTION_CREATE_CUSTOM_ANALYSIS,
  async (params: CreateCustomAnalysisParams) => {
    await graphqlService.execute(mutationCreateCustomAnalysis(params))
  }
)

export const ACTION_FETCH_DIRECTORIES = 'docAnalytics/fetch/directories'
export const fetchDirectories = createAsyncThunk(
  ACTION_FETCH_DIRECTORIES,
  async (params: DirectoryDetailsListParams, { rejectWithValue }) => {
    try {
      const resultRaw = await graphqlService.execute(queryDirectoryList(params))
      return {
        path: params.path,
        data: mapDirectoriesList(resultRaw)
      }
    } catch (error) {
      const messageArr =
        (error as { errors: { message }[] })?.errors?.map?.((error) => error?.message || '') || []
      return rejectWithValue({
        path: params.path,
        message: messageArr[0] || 'Failed to fetch directories'
      })
    }
  }
)

export const ACTION_FETCH_MORE_DIRECTORIES = 'docAnalytics/fetch/moreDirectories'
export const fetchMoreDirectories = createAsyncThunk(
  ACTION_FETCH_MORE_DIRECTORIES,
  async (params: DirectoryDetailsListParams, { rejectWithValue }) => {
    try {
      const resultRaw = await graphqlService.execute(queryDirectoryList(params))
      return {
        path: params.path,
        data: mapDirectoriesList(resultRaw)
      }
    } catch (error) {
      const messageArr =
        (error as { errors: { message }[] })?.errors?.map?.((error) => error?.message || '') || []
      return rejectWithValue({
        path: params.path,
        message: messageArr[0] || 'Failed to fetch directories'
      })
    }
  }
)

const docAnalyticsSlice = createSlice({
  name: 'docAnalytics',
  initialState,
  reducers: {
    setDirectoryError: (state, { payload }) => {
      const { path, message } = payload
      if (state.directories[path]) {
        state.directories[path].error = message
      }
    },

    resetWidgetData: (
      state,
      {
        payload
      }: PayloadAction<{
        key: WidgetKeys
      }>
    ) => {
      state[payload.key] = []
    },
    resetDocAnalyticsStatus: (state) => {
      state.docAnalyticsStatus = initialState.docAnalyticsStatus
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDocAnalyticsStatus.fulfilled, (state, { payload }) => {
      state.docAnalyticsStatus = payload
    })
    builder.addCase(fetchDocAnalyticsFilesList.fulfilled, (state, action) => {
      state.filesList = action.payload.list
    })
    builder.addCase(fetchDocAnalyticsFilesCount.fulfilled, (state, action) => {
      state.filesCount = action.payload.total
    })
    builder.addCase(fetchSitesFilter.fulfilled, (state, action) => {
      state.sitesFilterList = action.payload.list
    })
    builder.addCase(fetchFileTypeFilter.fulfilled, (state, { payload }) => {
      state.fileTypeFilterList = payload
    })
    builder.addCase(fetchSkippedFileTypeFilter.fulfilled, (state, { payload }) => {
      state.fileTypeSkippedFilterList = payload
    })
    builder.addCase(fetchWidgetFileSize.fulfilled, (state, { payload }) => {
      state.fileSizeWidget = payload
    })

    builder.addCase(fetchWidgetLastUpdated.fulfilled, (state, { payload }) => {
      state.lastUpdatedWidget = payload
    })
    builder.addCase(fetchWidgetCreationTime.fulfilled, (state, { payload }) => {
      state.creationTimeWidget = payload
    })
    builder.addCase(fetchWidgetSkipReason.fulfilled, (state, { payload }) => {
      state.skipReasonWidget = payload
    })
    builder.addCase(fetchCustomReportsList.fulfilled, (state, { payload }) => {
      state.customReports.list = payload.list
    })
    builder.addCase(fetchSharesDirectoryDetailsList.fulfilled, (state, { payload }) => {
      state.directoryDetails.shares = payload.list
    })
    builder.addCase(fetchDrivesDirectoryDetailsList.fulfilled, (state, { payload }) => {
      state.directoryDetails.drives = payload.list
    })
    builder.addCase(fetchSharesDirectoryDetailsCount.fulfilled, (state, { payload }) => {
      state.directoryDetails.sharesCount = payload.total
    })
    builder.addCase(fetchDrivesDirectoryDetailsCount.fulfilled, (state, { payload }) => {
      state.directoryDetails.drivesCount = payload.total
    })
    builder.addCase(fetchDirectories.fulfilled, (state, { payload }: { payload: any }) => {
      const { path, data } = payload
      state.directories[path] = data
    })
    builder.addCase(fetchDirectories.rejected, (state, { payload }: { payload: any }) => {
      const { path, message } = payload
      if (state.directories[path]) {
        state.directories[path].error = message as string
      } else {
        state.directories[path] = { error: message as string }
      }
    })
    builder.addCase(fetchMoreDirectories.fulfilled, (state, { payload }: { payload: any }) => {
      const { path, data } = payload
      if (state.directories[path].list) {
        state.directories[path].list = [...(state.directories[path].list || []), ...data.list]
      }
    })
  }
})

export const {
  resetDocAnalyticsStatus,
  setDirectoryError,
  resetWidgetData
} = docAnalyticsSlice.actions
export default docAnalyticsSlice.reducer
