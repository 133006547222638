import {
  mapQueryNotificationsList,
  queryNotificationsList,
  queryNotificationsListCompact
} from './queries'
import graphqlService from '../../services/graphqlService'
import { PAGE } from '../../constants'
import { FilterParams, SORT_ORDER } from '../../interfaces'
import { getSortDirection, SortParams } from '../../utils/sortUtil'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export enum NotificationStatus {
  PENDING = 'PENDING',
  AVAILABLE = 'AVAILABLE',
  EXPIRED = 'EXPIRED'
}

export type NotificationIconName = 'csv' | string
export type NotificationAttachment = {
  id: string
  name: string
  url: string
  notificationId: string
}

export type Notification = {
  id: string
  title: string
  updateTimestamp: string
  notifyEmails: string[]
  icon?: NotificationIconName
  attachments?: NotificationAttachment[]
  isPending: boolean
  status?: NotificationStatus
  module: {
    id: string
    type: string
    severity?: string
    assignee: string
    datasourceType: string
    datasourceId: string
  }
}

interface NotificationsState {
  list?: Notification[]
  listCompact?: Notification[]
  total?: number
  sort: SortParams
}

export type IGetListParams = {
  [PAGE]?: number
  filters?: FilterParams
  startDate?: number
}

export const initialState: NotificationsState = {
  sort: {
    column: 'updateTimestamp',
    direction: SORT_ORDER.DESC
  }
}

export const ACTION_NOTIFICATIONS_LIST_FETCH = 'notifications/list'
export const fetchNotificationsList = createAsyncThunk(
  ACTION_NOTIFICATIONS_LIST_FETCH,
  async (params: IGetListParams) => {
    const resultRaw = await graphqlService.execute(queryNotificationsList(params))
    return mapQueryNotificationsList(resultRaw)
  }
)

export const ACTION_NOTIFICATIONS_LIST_COMPACT_FETCH = 'notifications/list/compact'
export const fetchNotificationsListCompact = createAsyncThunk(
  ACTION_NOTIFICATIONS_LIST_COMPACT_FETCH,
  async (params: IGetListParams) => {
    const resultRaw = await graphqlService.execute(queryNotificationsListCompact(params))
    return mapQueryNotificationsList(resultRaw)
  }
)

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    clearList: (state) => {
      state.list = initialState.list
      state.total = initialState.total
    },
    clearListCompact: (state) => {
      state.listCompact = initialState.list
    },
    setSort: (state, { payload }) => {
      state.sort = getSortDirection(state.sort, payload.column)
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotificationsList.fulfilled, (state, { payload }) => {
      state.list = payload.list
      state.total = payload.total
    })
    builder.addCase(fetchNotificationsListCompact.fulfilled, (state, { payload }) => {
      state.listCompact = payload.list
    })
  }
})

export const { clearListCompact, clearList, setSort } = notificationsSlice.actions

export default notificationsSlice.reducer
