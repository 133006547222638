import {
  CreateCustomAnalysisParams,
  CustomReportsListObj,
  CustomReportsListParams,
  CustomReportsListResponseType,
  DirectoryDetailsCountResponseType,
  DirectoryDetailsList,
  DirectoryDetailsListParams,
  DocAnalyticsFilesCountResponseType,
  DocAnalyticsFilesObject,
  DocAnalyticsFilesResponseType,
  DrivesDirectoryDetailsListObj,
  DrivesDirectoryDetailsResponseType,
  FetchDocAnalyticsFilesListParams,
  SharesDirectoryDetailsListObj,
  SharesDirectoryDetailsResponseType,
  SitesFilterObj,
  SitesFilterParams,
  SitesFilterResponseType,
  STATUS
} from './docAnalyticsSlice'
import { LIMIT_DEFAULT, CUSTOM_REPORTS_LIMIT_DEFAULT, GRAPHQL_LIMIT_DEFAULT } from '../../constants'
import { getAfterCursor, parameterizeArrayofObjects } from '../../utils/graphqlUtil'
import { gql } from 'graphql-request'

// Fetch Files List
export const queryDocAnalyticsFilesList = (params: FetchDocAnalyticsFilesListParams): string => {
  const { datasourceId, filters, page } = params

  const cursor = getAfterCursor(page, LIMIT_DEFAULT)
  let paramStr = ''
  const filterString = filters && filters.filter ? parameterizeArrayofObjects(filters.filter) : ''
  const booleanFilterString =
    filters && filters.booleanFilter ? parameterizeArrayofObjects(filters.booleanFilter) : ''

  if (datasourceId) {
    paramStr += `, datasourceId:"${datasourceId}"`
  }
  if (filterString) {
    paramStr += `, filter: ${filterString}`
  }
  if (booleanFilterString) {
    paramStr += `, booleanFilter: ${booleanFilterString} `
  }
  if (paramStr) {
    paramStr = `(first: ${LIMIT_DEFAULT}${paramStr},after:"${cursor}")`
  }
  return gql`
      {
        docAnalyticsObject${paramStr} {
          edges {
            node {
              id
              name
              extension
              size
              skipped
              skipReason
              checksum
              lastModifiedTimestamp
              createdTimestamp
              objectLink
            }
          }
        }
      }
    `
}
export const mapQueryDocAnalyticsFilesList = (
  queryResponse: any
): DocAnalyticsFilesResponseType => {
  try {
    const list: DocAnalyticsFilesObject[] =
      queryResponse?.docAnalyticsObject?.edges.map(({ node }) => ({
        id: node.id,
        name: node.name,
        extension: node.extension,
        size: node.size,
        skipped: node.skipped,
        skipReason: node.skipReason || '',
        checksum: node.checksum || '',
        lastModifiedTimestamp: node.lastModifiedTimestamp,
        createdTimestamp: node.createdTimestamp || '',
        objectLink: node.objectLink
      })) || []

    return { list }
  } catch (e) {
    return { list: [] }
  }
}

// Fetch Files Count
export const queryDocAnalyticsFilesCount = (params: FetchDocAnalyticsFilesListParams): string => {
  const { datasourceId, filters, page } = params

  const cursor = getAfterCursor(page, LIMIT_DEFAULT)
  let paramStr = ''
  const filterString = filters && filters.filter ? parameterizeArrayofObjects(filters.filter) : ''
  const booleanFilterString =
    filters && filters.booleanFilter ? parameterizeArrayofObjects(filters.booleanFilter) : ''

  if (datasourceId) {
    paramStr += `, datasourceId:"${datasourceId}"`
  }
  if (filterString) {
    paramStr += `, filter: ${filterString}`
  }
  if (booleanFilterString) {
    paramStr += `, booleanFilter: ${booleanFilterString} `
  }
  if (paramStr) {
    paramStr = `(first: ${LIMIT_DEFAULT}${paramStr},after:"${cursor}")`
  }
  return gql`
      {
        docAnalyticsFilesCount: docAnalyticsObject${paramStr} {
          count
        }
      }
    `
}
export const mapQueryDocAnalyticsFilesCount = (
  queryResponse: any
): DocAnalyticsFilesCountResponseType => {
  try {
    const total = queryResponse?.docAnalyticsFilesCount?.count || 0

    return { total }
  } catch (e) {
    return { total: 0 }
  }
}

//Fetch Sites filter
export const queryDocAnalyticsSitesFilter = (params: SitesFilterParams): string => {
  const { datasourceId, searchQuery = '' } = params

  let paramStr = ''

  if (datasourceId) {
    paramStr += `, datasourceId:"${datasourceId}"`
  }
  if (searchQuery) {
    paramStr += `, searchQuery:"${searchQuery}"`
  }
  if (paramStr) {
    paramStr = `(first: ${LIMIT_DEFAULT}${paramStr},after:"")`
  }
  return gql`
      {
        docAnalyticsSite${paramStr}{
          edges{
            node{
              siteId, name
            }
          }
        }
      }
    `
}
export const mapQueryDocAnalyticsSitesFilter = (queryResponse: any): SitesFilterResponseType => {
  try {
    const list: SitesFilterObj[] =
      queryResponse?.docAnalyticsSite?.edges.map(({ node }) => ({
        siteId: node.siteId,
        name: node.name
      })) || []

    return { list }
  } catch (e) {
    return { list: [] }
  }
}

//Fetch custom reports list (current support only includes SMB)
export const queryCustomReportsList = (params: CustomReportsListParams): string => {
  const { filters, page = 1 } = params

  const cursor = getAfterCursor(page, LIMIT_DEFAULT)

  let paramStr = ''

  const filterString = filters && filters.filter ? parameterizeArrayofObjects(filters.filter) : ''
  const booleanFilterString =
    filters && filters.booleanFilter ? parameterizeArrayofObjects(filters.booleanFilter) : ''

  if (filterString) {
    paramStr += `, filter: ${filterString}`
  }
  if (booleanFilterString) {
    paramStr += `, booleanFilter: ${booleanFilterString} `
  }
  if (paramStr) {
    paramStr = `(first: ${CUSTOM_REPORTS_LIMIT_DEFAULT}${paramStr},after:"${cursor}", sortField: UPDATE_TIMESTAMP)`
  }

  return gql`
    query customReports {
      notification${paramStr} {
        count
        edges {
          node {
            id
            title
            updateTimestamp
            icon
            module {
              folderPath
            }
            notifiedAt
            attachments {
              id
              name
              status
              url
            }
          }
        }
      }
    }
  `
}
export const mapQueryCustomReportsList = (queryResponse: any): CustomReportsListResponseType => {
  try {
    const list: CustomReportsListObj[] =
      queryResponse?.notification?.edges.map(({ node }) => ({
        id: node.id,
        title: node.title,
        updateTimestamp: node.updateTimestamp,
        icon: node.icon,
        attachments: node.attachments,
        path: node.module?.folderPath,
        name: node.title,
        status: node.attachments?.[0]?.status,
        isPending: node.attachments?.[0]?.status === STATUS.PENDING
      })) || []

    return { list }
  } catch (e) {
    return { list: [] }
  }
}

//Fetch Directory Details List for SMB
export const querySharesDirectoryDetailsList = (params: DirectoryDetailsListParams): string => {
  const { dataSourceId, page = 1, path } = params

  const cursor = getAfterCursor(page, LIMIT_DEFAULT)

  let paramStr = ''

  if (dataSourceId) {
    paramStr += `, datasourceId:"${dataSourceId}"`
  }

  if (path) {
    paramStr += `, path:"${path}"`
  }

  if (paramStr) {
    paramStr = `(first: ${LIMIT_DEFAULT}${paramStr},after:"${cursor}")`
  }
  if (path) {
    return gql`
    query {
    directory${paramStr}{
        count
        edges{
            node{
                path
                size
                attributeInstancesCount
                piiDataDocumentsCount
                skippedDocumentsCount
                processedDocumentsCount
                documentsCount
            }
        }
    }
}
    
    `
  }
  return gql`
    query {
      fileShare${paramStr} {
        edges {
          node {
            name
            shareId
            size
            attributeInstancesCount
            piiDataDocumentsCount
            skippedDocumentsCount
            processedDocumentsCount
            documentsCount
          }
        }
      }
    }
  `
}
export const mapQuerySharesDirectoryDetailsList = (
  queryResponse: any
): SharesDirectoryDetailsResponseType => {
  try {
    const { fileShare, directory } = queryResponse
    const list: SharesDirectoryDetailsListObj[] = fileShare
      ? fileShare?.edges.map(({ node }) => ({
          shareId: node.shareId,
          name: node.name,
          size: node?.size,
          attributeInstancesCount: node?.attributeInstancesCount,
          piiDataDocumentsCount: node?.piiDataDocumentsCount,
          skippedDocumentsCount: node?.skippedDocumentsCount,
          processedDocumentsCount: node?.processedDocumentsCount,
          documentsCount: node?.documentsCount
        })) || []
      : directory?.edges.map(({ node }) => ({
          path: node.path,
          size: node?.size,
          attributeInstancesCount: node?.attributeInstancesCount,
          piiDataDocumentsCount: node?.piiDataDocumentsCount,
          skippedDocumentsCount: node?.skippedDocumentsCount,
          processedDocumentsCount: node?.processedDocumentsCount,
          documentsCount: node?.documentsCount
        })) || []

    return { list }
  } catch (e) {
    return { list: [] }
  }
}
//Count
export const querySharesDirectoryDetailsCount = (params: DirectoryDetailsListParams): string => {
  const { dataSourceId, page = 1, path } = params

  const cursor = getAfterCursor(page, LIMIT_DEFAULT)

  let paramStr = ''

  if (dataSourceId) {
    paramStr += `, datasourceId:"${dataSourceId}"`
  }
  if (path) {
    paramStr += `, path:"${path}"`
  }
  if (paramStr) {
    paramStr = `(first: ${LIMIT_DEFAULT}${paramStr},after:"${cursor}")`
  }
  if (path) {
    return gql`
    query {
      directory${paramStr} {
        count
      }
    }
    `
  }
  return gql`
    query {
      fileShare${paramStr} {
        count
      }
    }
  `
}
export const mapQuerySharesDirectoryDetailsCount = (
  queryResponse: any
): DirectoryDetailsCountResponseType => {
  try {
    const { fileShare, directory } = queryResponse
    const total = fileShare?.count || directory?.count || 0

    return { total }
  } catch (e) {
    return { total: 0 }
  }
}

//Fetch Directory Details List for One Drive & Sharepoint
export const queryDrivesDirectoryDetailsList = (params: DirectoryDetailsListParams): string => {
  const { dataSourceId, page = 1 } = params

  const cursor = getAfterCursor(page, LIMIT_DEFAULT)

  let paramStr = ''

  if (dataSourceId) {
    paramStr += `, datasourceId:"${dataSourceId}"`
  }
  if (paramStr) {
    paramStr = `(first: ${LIMIT_DEFAULT}${paramStr},after:"${cursor}")`
  }

  return gql`
    query {
      drive${paramStr} {
        edges {
          node {
            driveName
            driveId
            size
            attributeInstancesCount
            piiDataDocumentsCount
            skippedDocumentsCount
            processedDocumentsCount
            documentsCount
          }
        }
      }
    }
  `
}
export const mapQueryDrivesDirectoryDetailsList = (
  queryResponse: any
): DrivesDirectoryDetailsResponseType => {
  try {
    const list: DrivesDirectoryDetailsListObj[] =
      queryResponse?.drive?.edges.map(({ node }) => ({
        driveId: node?.driveId || '',
        driveName: node?.driveName || '',
        size: node?.size,
        attributeInstancesCount: node?.attributeInstancesCount,
        piiDataDocumentsCount: node?.piiDataDocumentsCount,
        skippedDocumentsCount: node?.skippedDocumentsCount,
        processedDocumentsCount: node?.processedDocumentsCount,
        documentsCount: node?.documentsCount
      })) || []

    return { list }
  } catch (e) {
    return { list: [] }
  }
}
//Count
export const queryDrivesDirectoryDetailsCount = (params: DirectoryDetailsListParams): string => {
  const { dataSourceId, page = 1 } = params

  const cursor = getAfterCursor(page, LIMIT_DEFAULT)

  let paramStr = ''

  if (dataSourceId) {
    paramStr += `, datasourceId:"${dataSourceId}"`
  }
  if (paramStr) {
    paramStr = `(first: ${LIMIT_DEFAULT}${paramStr},after:"${cursor}")`
  }

  return gql`
    query {
      drive${paramStr} {
        count
      }
    }
  `
}
export const mapQueryDrivesDirectoryDetailsCount = (
  queryResponse: any
): DirectoryDetailsCountResponseType => {
  try {
    const total = queryResponse?.drive?.count || 0

    return { total }
  } catch (e) {
    return { total: 0 }
  }
}

export const mutationCreateCustomAnalysis = (params: CreateCustomAnalysisParams): string => {
  const { path, name, datasourceId } = params

  return gql`
    mutation {
    createSilentNotification(
        actionInput: {
            clientMutationId: "1"
            datasourceId: "${datasourceId}"
            moduleType: ANALYSIS
            reportName: "${name}"
            folderPath: "${path}"
        }
    ) {
        clientMutationId
    }
}
    `
}

export const queryDirectoryList = (params: DirectoryDetailsListParams) => {
  const { dataSourceId, page = 1, path } = params

  const cursor = getAfterCursor(page, GRAPHQL_LIMIT_DEFAULT)

  let paramStr = ''

  if (dataSourceId) {
    paramStr += `, datasourceId:"${dataSourceId}"`
  }

  if (path) {
    paramStr += `, path:"${path}"`
  }

  if (paramStr) {
    paramStr = `(first: ${GRAPHQL_LIMIT_DEFAULT}${paramStr},after:"${cursor}")`
  }
  return gql`
  query directoryList {
    directory${paramStr}{
      count
        edges{
            node{
                name
                path
            }
        }
    }
}
  
  `
}

export const mapDirectoriesList = (raw: any): DirectoryDetailsList => {
  const list = raw?.directory?.edges?.map(({ node }: any) => ({
    name: node.name,
    path: node.path
  }))

  return {
    list,
    count: raw?.directory?.count,
    error: ''
  }
}
